module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Up Heal Spaces","short_name":"Up Heal Spaces","start_url":"/","lang":"pt-pt","background_color":"#ffffff","theme_color":"#d8e0de","display":"standalone","localize":[{"start_url":"/pt-pt/","lang":"pt-pt"},{"start_url":"/pt-br/","lang":"pt-br"}],"icon":"/opt/build/repo/src/images/symbol-dark.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"51552532b968675c53d09b6637c210b4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WB8XNCB","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
